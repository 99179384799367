<template>
  <div class="header">

    <div class="profil">
      <div class="profil__text">
        <h2 class="profil__name">{{ $store.state.user.jmeno }} {{ $store.state.user.prijmeni }}</h2>
        <span class="profil__pozice">
          {{ $store.state.user.nazev_role }},
          <span class="profil__pozice__katedra">{{ $store.state.user.zkratka_katedry }}</span>
        </span>
        <button class="profil__logout" @click="logout">logout</button>
      </div>
      <div class="profil__img">
        <button class="profil__img__settings" @click="openSettingsWindow()"></button>
      </div>
    </div>

    <div class="header__programy">
      <div class="header__programy__logo"></div>
      <router-link to="/">
        <button class="header__programy__item">Aktuální programy</button>
      </router-link>
      <router-link to="/historicke">
        <button class="header__programy__item">Historické programy</button>
      </router-link>
      <span class="header__programy--o-aplikaci">
        <router-link to="/o">
          <button class="header__programy__item">O aplikaci</button>
        </router-link>
      </span>
    </div>
    <div class="prispevek">
      <span class="prispevek__title">Finanční příspěvek</span>
      <div class="prispevek__neschvaleny">
        <span class="prispevek__label">Zamítnutý</span>
        <span class="prispevek__amount">{{ neschvaleny }},-</span>
      </div>
      <div class="prispevek__schvaleny">
        <span class="prispevek__label">Schválený</span>
        <span class="prispevek__amount">{{ schvaleny }},-</span>
      </div>
      <div class="prispevek__jednani">
        <span class="prispevek__label">Založený</span>
        <span class="prispevek__amount">{{ jednani }},-</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderUser",

  components: {
  
  },

  data() {
    return {
      person: null,
    };
  },

  computed: {
    schvaleny() {
      var result = 0;
      this.$store.state.programy.map(program => {
        if (program.status == 2) {
          if (program.financni_hodnota != null) {
            result += parseFloat(program.financni_hodnota);
          }
        }
      });
      return result;
    },
    neschvaleny() {
      var result = 0;
      this.$store.state.programy.map(program => {
        if (program.status == 3 || program.status == 4) {
          if (program.financni_hodnota != null) {
            result += parseFloat(program.financni_hodnota);
          }
        }
      });
      return result;
    },
    jednani() {
      var result = 0;
      this.$store.state.programy.map(program => {
        if (program.status == 1) {
          if (program.financni_hodnota != null) {
            result += parseFloat(program.financni_hodnota);
          }
        }
      });
      return result;
    }
  },
  methods: {
    logout() {
      this.$http.get("/api/user/logout").then(() => {
        this.$store.dispatch("logout").then(() => {
          window.localStorage.clear();
          this.$router.push("/login");
        });
      });
    },
    openSettingsWindow() {
      this.$emit("openSettings");
    }
  }
};
</script>