<template>
    <div class="admin__box">
       
        <div><p>{{ cele_jmeno }}</p></div>  
        <div><p>{{ oblast }}</p></div> 
        <div><p>{{ veda }}</p></div>
        <div><p>{{ nazev}}</p></div>
        
        <div><p>{{ datum_zalozeni(datum) }}</p></div>
        <div><p>Finanční hodnota: {{ financni_hodnota }},-</p></div>

        <button class="admin__box__button" v-on:click="emit_program">Detail programu</button>
        <div class="status"><p >{{ status_nazev }}</p></div>
       
        
    
      <!--  <div v-if="status_programy.length > 1" class="select-wraper">-->
      <!--  <select class="program-modal__select" id="status_programy" v-model='selectedstatus' @change="changeStatus">      -->   
      <!--  <option v-for="status_program in status_programy" :key="status_program.id_status" :value="status_program.id_status">{{ status_program.nazev }}</option> -->
       <!-- </select> -->
       <!-- </div>     -->

                
       
      
    </div>




</template>

<script>
import moment from 'moment'
//import 'moment/locale/cs';
moment.locale('cs');


export default {
    name: "adminprogram",
    data() {
        return {
            ide: this.id,
            //změna statusu programy
            status_programy: [],
            selectedstatus: this.status,


            // data pro identifikátor
            rok: null,
            id_projekt_nazev:null

        }
    },
    props: {
        nazev: String,
        oblast: String,
        id: Number,
        status: Number,
        user: Object,
        datum: String,
        status_nazev: String,
        veda: String,
        financni_hodnota:String,
        cele_jmeno:String,
        id_nazev:String,
    },
    methods: {

      // vrácení hodnoty child -> parrent - do admina ID
        emit_program() {
			this.$emit('show_modal', this.id)
		},


        changeStatus() {
            window.console.log(this.selectedstatus)
            let data = {id: this.id, status: this.selectedstatus}
            this.$http
            .post("/api/admin/changeprogramstatus", data)
            .then((response) => {
                window.console.log(response.data)
                this.$emit('reload')
            })
        },
    
   
   datum_zalozeni(datum) {
         return moment(datum).format("LL");
    },


/*
   id_projektu(datum, prijmeni, oblast, id ) {
        this.rok = moment(datum).format("YYYY");
        this.id_projekt_nazev = this.rok + "_" + this.replaceDiacritics(prijmeni) + "_" + this.replaceDiacritics(oblast) + "_" + id
        this.id_projekt_nazev  = this.id_projekt_nazev.toLowerCase().split(' ').join('_');
        return this.id_projekt_nazev;
    },
*/

   replaceDiacritics(text) {     
   const diacr =     "ÁÄČÇĎÉĚËÍŇÓÖŘŠŤÚŮÜÝŽáäčçďéěëíňóöřšťúůüýž";
   const diacrSubs = "AACCDEEEINOORSTUUUYZaaccdeeeinoorstuuuyz";

   if (text.length < diacr.length) {
    for (let i = 0; i < text.length; i++) {
      let indexOfSpecChar = diacr.indexOf(text[i]);
      if (indexOfSpecChar > -1) { text = text.replace(new RegExp(diacr[indexOfSpecChar], 'g'), diacrSubs[indexOfSpecChar]); }
    }
    } else { for (let j = 0; j < diacr.length; j++) { text = text.replace(new RegExp(diacr[j], 'g'), diacrSubs[j]); }
    }
    return text;
   }

    },




    mounted() {
    this.$http
        .get('/api/status_program/all')
        .then((response) => {
            this.status_programy = response.data
        })


    },





}
</script>