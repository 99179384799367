<template>
  <div class="modal-vue v--modal-overlay scrollable">
    <div
      class="modal-wrapper"
      v-if="showModal"
      :class="{nacteno: this.dataNactena}"
    >
      <button
        class="modal-admin__button__zpet"
        v-if="showModal"
        v-on:click="$emit('zavrit')"
      >Zpět</button>

      <div
        class="modal_loading"
        v-if="showModal"
        :class="{nacteno: this.dataNactena}"
      >
        <span></span>
      </div>

      <div
        class="modal"
        :class="{nacteno: this.dataNactena}"
      >
        <h2>{{ replaceDiacritics( this.id_nazev ) }}</h2>

 


        <div class="column2">
          <div class="modal__financnihodnota">
            <p>Finanční Hodnota: {{this.financni_hodnota}},-</p>
          </div>
          <div class="modal__financnihodnota">
            <label for="castka-hostujici-profesor">Zadejte finanční hodnotu</label>
            <div class="input_wrapper">
              <input
                type="text"
                id="castka-hostujici-profesor"
                v-model="formattedCurrencyValue"
                @blur="focusOut"
                @focus="focusIn"
              />
            </div>
          </div>

          <div
            v-if="status_programy.length > 1"
            class="select-wraper"
          >
            <select
              class="program-modal__select"
              id="status_programy"
              v-model="selectedstatus"
              @change="changeStatus"
            >
              <option
                v-for="status_program in status_programy"
                :key="status_program.id_status"
                :value="status_program.id_status"
              >{{ status_program.nazev }}</option>
            </select>
          </div>

          <p>
            <strong>Jméno uživatele:</strong>
            {{this.titul}} {{this.jmeno}} {{this.prijmeni}}
          </p>
          <p>
            <strong>Oblast:</strong>
            {{this.oblast_name}}
          </p>
          <p>
            <strong>Program:</strong>
            {{this.nazev_program}}
          </p>
          <p>
            <strong>Datum založení:</strong>
            {{ datum(this.datum_zalozeni)}}
          </p>

          <h4
            v-if="id_veda != 4 && id_veda != 7"
            class="nadpis-h4"
          >Podpora osobního růstu</h4>
          <div
            v-for="podpora_osobniho_rust in podpora_osobniho_rustu"
            :key="podpora_osobniho_rust"
          >
            <div class="column1">
              <p>
                {{podpora_osobniho_rust.nazev}}
                <span v-if="vlastni_podpora == true && podpora_osobniho_rust.id_checkboxu == 6">"{{popis_navrhu_vlastni_podpory}}" v hodnotě {{odhadovana_cena_vlastni_podpory}} Kč</span>
              </p>
            </div>
          </div>
        </div>

        <div class="column2">
          <div
            class="hostujici-profesor"
            v-if="id_veda == 2"
          >
            <p>
              <strong>Předpokládaný termín odevzdání:</strong>
              {{ datum(this.datum_do)}}
            </p>
            <p>
              <strong>Obor:</strong>
              {{this.ciselnik_obor_nazev}}
            </p>
          </div>

          <div
            class="hostujici-profesor"
            v-if="id_veda == 3"
          >
            <p>
              <strong>Název habilitační práce:</strong>
              {{this.nazev_jmeno}}
            </p>
            <p>
              <strong>Předpokládaný termín odevzdání:</strong>
              {{ datum(this.datum_do) }}
            </p>
            <p>
              <strong>Obor:</strong>
              {{this.ciselnik_obor_nazev}}
            </p>
          </div>

          <div
            class="hostujici-profesor"
            v-if="id_veda == 4"
          >
            <p>
              <strong>Jméno hostujiciho profesora:</strong>
              {{this.nazev_jmeno}}
            </p>
            <p>
              <strong>Datum od:</strong>
              {{ datum(this.datum_od) }}
            </p>
            <p>
              <strong>Datum do:</strong>
              {{ datum(this.datum_do)}}
            </p>

            <div v-if="hostujici_profesor_cinnosti.length > 0">
              <h3>Činnosti hostující profesor:</h3>
              <div
                v-for="hostujici_profesor_cinnost in hostujici_profesor_cinnosti"
                :key="hostujici_profesor_cinnost.cinnost"
              >{{hostujici_profesor_cinnost.cinnost}}</div>
            </div>
          </div>

          <div
            class="hostujici-profesor"
            v-if="id_veda == 7"
          >
            <p>
              <strong>Předmět/y:</strong>
              {{this.nazev_jmeno}}
            </p>

            <p>
              <strong>Popis předpokládaného výstupu a jeho využití:</strong>
              {{this.popis}}
            </p>
          </div>

          <div
            class="hostujici-profesor"
            v-if="id_veda == 5 || id_veda == 6"
          >
            <p>
              <strong>Název projektu:</strong>
              {{this.nazev_jmeno}}
            </p>
            <p>
              <strong>Agentura:</strong>
              {{this.ciselnik_agentura}}
            </p>
            <p>
              <strong>Popis projektu:</strong>
              {{this.popis}}
            </p>
          </div>

          <div
            class="column1"
            v-if="id_veda != 4 && id_veda != 7"
          >
            <h3  v-if="program_parametry.length != 0">Parametry projektu</h3>
            
            <div
              v-for="program_parametr in program_parametry"
              class="program_parametr"
              :key="program_parametr.tema"
            >
              <div class="column1">
                <p>
                  <strong>Časopis:</strong>
                  {{program_parametr.casopis}}
                </p>
              </div>
              <div class="column2">
                <p>
                  <strong>Q:</strong>
                  {{program_parametr.q}}
                </p>
              </div>
              <div class="column2">
                <p>
                  <strong>FORD:</strong>
                  {{program_parametr.hodnota_ford}}
                </p>
              </div>
              <div class="column1">
                <p>
                  <strong>Autorský podíl:</strong>
                  {{program_parametr.autorsky_podil}}
                </p>
              </div>
              <div class="column1">
                <p>
                  <strong>Téma:</strong>
                  {{program_parametr.tema}}
                </p>
              </div>
              <div class="column1">
                <p>
                  <strong>Cena parametru:</strong>
                  {{program_parametr.cena}},-
                </p>
              </div>
            </div>
          </div>

          <div
            class="column1"
            v-if="id_veda == 5 || id_veda == 6"
          >
            <h3>Spolupracovníci</h3>
            <div
              v-for="spolupracovnik in spolupracovnici"
              :key="spolupracovnik.jmeno"
            >
              <strong>{{ spolupracovnik.jmeno }}</strong>:
              <span>{{ spolupracovnik.podil}} %</span>
            </div>
            <div v-if="spolupracovnici.length == 0">Nebyl uveden žádný spolupracovník.</div>
          </div>
        </div>

        <h3
          v-if="this.files != 0"
          class="nadpis_popis"
        >Soubory nahrané uživatelem:</h3>

        <div
          class="vlozit_soubor__zaznam"
          v-if="this.files != 0"
        >
          <a
            v-for="fil in this.files"
            :key="fil.nazev_souboru"
            target="_blank"
            :href="'../file_user/' + fil.nazev_souboru"
            class="vlozit_soubor__zaznam__item"
          >
            <div class="radek">
              <p class="datum">{{fil.datum }}</p>
              <p class="popis">{{fil.popis}}</p>
            </div>

            <p class="nazev">{{fil.nazev_souboru}}</p>
          </a>
        </div>

        <div class="column1">
          <h3>Konverzace s uživatelem:</h3>
          <div id="chat">
            <div id="chat_div">
              <div
                id="messages-window"
                v-if="messages.length > 0"
              >
                <div
                  v-for="message in messages"
                  :key="message"
                  v-bind:class="'message ' + (message.id_uzivatele==id_uzivatel ? 'vlastni' : 'cizi')"
                >
                  <div class="zprava_div">
                    <span
                      class="user_img"
                      v-if="message.id_uzivatele!=id_uzivatel"
                    ></span>

                    <div class="zprava_sekce">
                      <span
                        class="uzivatel"
                        v-if="message.id_uzivatele==id_uzivatel"
                      >Vy</span>
                      <span
                        class="uzivatel"
                        v-else
                      >{{message.cele_jmeno}}</span>

                      <div class="zprava">{{message.content}}</div>
                    </div>

                    <span
                      class="user_img"
                      v-if="message.id_uzivatele==id_uzivatel"
                    ></span>

                    <div class="clearFix"></div>
                  </div>

                  <div class="clearFix"></div>
                </div>
                <div class="clearFix"></div>
              </div>
            </div>

            <input
              type="text"
              v-model="nova_zprava"
            />

            <button v-on:click="addMessage">Odeslat zprávu</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>








<script>
import moment from "moment";
//import 'moment/locale/cs';
moment.locale("cs");

export default {
  name: "adminmodal",
  data() {
    return {
      showModal: true,
      edit: null,

      agentury: [],
      nazev_test: null,

      id_uzivatel: this.$store.state.user.id_uzivatel,

      hod: null,

      // přidání hodnoty u hostujícího profesora
      currencyValue: 0,
      formattedCurrencyValue: "0 Kč",

      status_programy: [],
      selectedstatus: this.status,

      //chat
      nova_zprava: "",
      //  messages: [{id_uzivatel: 2,  id_projekt:110, content:'Nová zpráva'},],
      messages: [],

      podpora_osobniho_rustu: [],

      // Popis projektu text area validace znaků
      popis_projektu_znaky: 256,
      popis_projektu_text: "",

      // select program
      titul: null,
      jmeno: null,
      prijmeni: null,

      id_uzivatel_program: null,

      oblast_name: null,
      nazev_program: null,
      datum_zalozeni: null,
      status_nazev: null,
      id_veda: null,

      nazev_jmeno: null,
      datum_od: null,
      datum_do: null,
      popis: null,
      specifikace_ciselnik: null,
      financni_hodnota: null,
      ciselnik_obor_nazev: null,
      ciselnik_agentura: null,
      id_nazev: "",

      popis_navrhu_vlastni_podpory: "",
      odhadovana_cena_vlastni_podpory: 0,
      vlastni_podpora: false,

      spolupracovnici: null,

      files: [],

      dataNactena: false,

      //hostujici_profesor_cinnosti
      hostujici_profesor_cinnosti: [],

      //program parametr
      program_parametry: []
    };
  },

  methods: {
    /*
   id_projektu(datum, prijmeni, oblast, id ) {
        this.rok = moment(datum).format("YYYY");
        this.id_projekt_nazev = this.rok + "_" + this.replaceDiacritics(prijmeni) + "_" + this.replaceDiacritics(oblast) + "_" + id
        this.id_projekt_nazev  = this.id_projekt_nazev.toLowerCase().split(' ').join('_');
        return this.id_projekt_nazev;
    },

*/
    replaceDiacritics(text) {
      const diacr = "ÁÄČÇĎÉĚËÍŇÓÖŘŠŤÚŮÜÝŽáäčçďéěëíňóöřšťúůüýž";
      const diacrSubs = "AACCDEEEINOORSTUUUYZaaccdeeeinoorstuuuyz";

      if (text.length < diacr.length) {
        for (let i = 0; i < text.length; i++) {
          let indexOfSpecChar = diacr.indexOf(text[i]);
          if (indexOfSpecChar > -1) {
            text = text.replace(
              new RegExp(diacr[indexOfSpecChar], "g"),
              diacrSubs[indexOfSpecChar]
            );
          }
        }
      } else {
        for (let j = 0; j < diacr.length; j++) {
          text = text.replace(new RegExp(diacr[j], "g"), diacrSubs[j]);
        }
      }
      return text;
    },

    datum(datum) {
      return moment(datum).format("LL");
    },

    focusOut: function() {
      //replace zda je vložená správná hodnota
      this.currencyValue = parseFloat(
        this.formattedCurrencyValue.replace(/[^\d.]/g, "")
      );
      // když nečíselná hodnota tak 0
      if (isNaN(this.currencyValue)) {
        this.currencyValue = 0;
      }

      // formát inputu
      this.formattedCurrencyValue =
        this.currencyValue
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + " Kč ";

      let data = {
        hodnota: this.currencyValue,
        id: this.id
      };
      this.$http
        .post("/api/admin/add_financni_hodnota", data)
        .then(response => {
          if (response.data == "success") {
            window.console.log("hodnota přidána");
          } else {
            alert("error");
          }
        });
    },

    focusIn: function() {
      this.formattedCurrencyValue = this.currencyValue.toString();
    },

    validace_input_chat() {
      if (!this.nova_zprava) {
        alert("Prosím vyplňte text zprávy");
        return false;
      }
      return true;
    },

    addMessage() {
      if (this.validace_input_chat()) {
        this.messages.push({
          id_uzivatele: this.id_uzivatel,
          id_projekt: this.id,
          content: this.nova_zprava
        });
        let data = {
          zprava: this.nova_zprava,
          id: this.id,
          id_uzivatel: this.id_uzivatel,
          id_uzivatel_mailing: this.id_uzivatel_program

        };



        this.$http.post("/api/admin/add_zpravu", data).then(response => {
          if (response.data == "success") {
            window.console.log("Zpráva přidána");
          } else {
            alert("Došlo k problému při přidávání zprávy!");
          }
        });
        this.nova_zprava = "";
      }
    },

    changeStatus() {
      window.console.log(this.selectedstatus);
      let data = { id: this.id, status: this.selectedstatus };
      this.$http.post("/api/admin/changeprogramstatus", data).then(response => {
        window.console.log(response.data);
        this.$emit("reload");
      });
    },

    ziskejSpolupracovnikyProjektu: function() {
      this.$http
        .post("/api/program/getspolupracovnici", this.id)
        .then(response => {
          this.spolupracovnici = response.data;
        });
    },

    zaznamGoogleAnalytics: function() {
      this.$gtag.event("Otevření AdminModal", {
        event_category: "Práce s aplikací"
      });
    },

    ziskejStatusProgramy: function() {
      this.$http.get("/api/status_program/all").then(response => {
        this.status_programy = response.data;
      });
    },

    ziskejHostujiciProgramCinnosti: function() {
      this.$http
        .get("/api/admin/hostujici_profesor_cinnosti/" + this.id)
        .then(response => {
          this.zprava = response.data;
          this.zprava.forEach(enter => {
            this.hostujici_profesor_cinnosti.push({ cinnost: enter.cinnost });
          });
        });
    },

    ziskejProgramParametr: function() {
      this.$http
        .get("/api/admin/program_parametr/" + this.id)
        .then(response => {
          this.parametr = response.data;
          this.parametr.forEach(enter => {
            this.program_parametry.push({
              tema: enter.tema,
              casopis: enter.casopis,
              ford: enter.ford,
              hodnota_ford: enter.hodnota,
              q: enter.Q,
              autorsky_podil: enter.autorsky_podil,
              cena: enter.cena
            });
          });
        });
    },

    ziskejSoubory: function() {
      this.$http
        .get("/api/fileuploader/nacteni_souboru/" + this.id)
        .then(response => {
          this.files = response.data;
        });
    },

    nactiCheckboxy: function() {
      this.$http
        .get("/api/admin/nacteni_checkbox/" + this.id)
        .then(response => {
          this.podpora_osobniho_rustu = response.data;
        });
    },

    nactiOsobniPodporu: function() {
      this.$http
        .get("/api/admin/nacteni_osobni_podpory/" + this.id)
        .then(response => {
          window.console.log(response.data);
          if (response.data.length != 0) {
            this.vlastni_podpora = true;
            this.popis_navrhu_vlastni_podpory = response.data[0].popis;
            this.odhadovana_cena_vlastni_podpory =
              response.data[0].odhadovana_cena;
          }
        });
    },

    nactiZpravy: function() {
      this.$http.get("/api/admin/modal_zpravy/" + this.id).then(response => {
        this.zprava = response.data;
        this.zprava.forEach(enter => {
          this.messages.push({
            id_uzivatele: enter.id_uzivatel,
            id_projekt: this.id,
            content: enter.zprava,
            cele_jmeno: enter.cele_jmeno
          });
        });

        this.dataNactena = true;
      });
    },

    ziskejProgram: function() {
      this.$http.get("/api/admin/program_detail/" + this.id).then(response => {
        this.program = response.data[0];
        this.titul = this.program.titul;
        this.jmeno = this.program.jmeno;
        this.prijmeni = this.program.prijmeni;
        
        this.id_uzivatel_program = this.program.id_uzivatel;

        this.oblast_name = this.program.oblast_name;
        this.nazev_program = this.program.nazev;
        this.id_veda = this.program.id_veda;
        this.datum_zalozeni = this.program.datum_zalozeni;
        this.status_nazev = this.program.status_nazev;
        this.selectedstatus = this.program.id_status;

        this.id_nazev = this.program.id_nazev;

        this.nazev_jmeno = this.program.nazev_jmeno;
        this.datum_od = this.program.datum_od;
        this.datum_do = this.program.datum_do;
        this.popis = this.program.popis;
        this.specifikace_ciselnik = this.program.specifikace_ciselnik;

        this.financni_hodnota = this.program.financni_hodnota;

        // hostujici profesor přidat peníze
        this.currencyValue = this.program.financni_hodnota;
        if (this.program.financni_hodnota == null) {
          this.formattedCurrencyValue = 0 + " Kč";
        } else {
          this.formattedCurrencyValue = this.program.financni_hodnota + " Kč";
        }

        this.ciselnik_obor_nazev = this.program.ciselnik_obor_nazev;
        this.ciselnik_agentura = this.program.ciselnik_agentura;

        if (this.id_veda == 5 || this.id_veda == 6) {
          this.ziskejSpolupracovnikyProjektu();
        }

        if (this.id_veda == 4) {
          this.ziskejHostujiciProgramCinnosti();
        }

        this.ziskejProgramParametr();

        this.ziskejSoubory();

        this.nactiCheckboxy();

        this.nactiOsobniPodporu();

        // aktualne se bere nactiZpravy jako posledni ukon po kterem mizi loading, je potreba ji tedy vzdy provest
        this.nactiZpravy();
      });
    }
  },

  mounted() {
    var body = document.body;
    body.classList.add("hideOverflow");
    this.zaznamGoogleAnalytics();
    this.ziskejStatusProgramy();
    this.ziskejProgram();
  },

  props: {
    nazev: String,
    id: String,
    ide: String,
    status: String,

    test: String
  }
};
</script>