<template>
  <div class="header headerAdmin">
    <div class="profil">
      <div class="profil__text">
        <h2 class="profil__name">{{ $store.state.user.jmeno }} {{ $store.state.user.prijmeni }}</h2>
        <span class="profil__pozice">Admin</span>
        <button class="profil__logout" @click="logout">logout</button>
      </div>
      <div class="profil__img"></div>
    </div>





    <div class="header__programy">
      <div class="header__programy__logo"></div>
      <div class="header__programy__nadpis">POKR Administrace</div>
    </div>




<div>
      <router-link to="/admin">
        <button class="header__programy__item">Aktuální programy</button>
      </router-link>
</div>



<div>
      <router-link to="/admin_historicke">
        <button class="header__programy__item">Historické programy</button>
      </router-link>
</div>



  </div>
</template>

<script>
export default {
  name: "headeradmin",
  methods: {
    logout() {
      this.$http.get("/api/user/logout").then(() => {
        this.$store.dispatch("logout").then(() => {
          window.localStorage.clear();
          this.$router.push("/login");
        });
      });
    }
  }
};
</script>